import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import axios from "axios";
import { Field, Form } from "react-final-form";
import ReactPaginate from "react-paginate";
import { Input } from "components/Form/Inputs/TextField";
import {
  Fieldset,
  FormSection,
  Legend,
  Section,
} from "components/Form/section";
import {
  columnsForLeadTable,
  disbursementStages,
  errorMessages,
} from "lib/utils/constants";
import {
  filterLeadByStageApi,
  searchLeadApi,
} from "lib/api/disbursementApis";
import SimpleTable from "components/common/Table";
import { isValidLeadSearchInput } from "lib/utils/validations";

function Search() {
  const [leadList, setLeadList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emptyList, setEmptyList] = useState(true);
  const [searchSuccess, setSearchSuccess] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [filterStage, setFilterStage] = useState(null);

  const router = useRouter();

  useEffect(() => {
    setError(null);
    setLeadList([]);
  }, []);

  useEffect(() => {
    if (filterStage) {
      search(null, filterStage, 0);
    }
  }, [filterStage]);

  async function onSubmit(data) {
    search(data?.search?.key);
  }

  const handlePageClick = (event) => {
    const pageNo = event.selected;
    search(null, filterStage, pageNo);
  };

  async function search(searchKey, filter, pageNo) {
    setLeadList([]);
    setLoading(true);
    setError(null);
    setPageCount(0);
    let endpoint;
    let params;

    if (searchKey) {
      setFilterStage(null);
      endpoint = searchLeadApi;
      params = {
        params: {
          searchKey: searchKey,
        },
      };
    }
    if (filter) {
      endpoint = filterLeadByStageApi(filter, pageNo);
      params = null;
    }

    axios.get(endpoint, params)
      .then((response) => {
        setLoading(false);
        if (response) {
          setSearchSuccess(searchKey ? searchKey : filter);
          setLeadList(
            searchKey ? response.data : response?.data?.data?.leadList
          );
          setEmptyList(false);
          if (filter) {
            setPageCount(response?.data?.data?.totalPages);
          }
          const length = searchKey
            ? response?.data?.data
            : response?.data?.data?.leadList?.length;
          if (length == 0) {
            setEmptyList(true);
            setSearchSuccess(null);
            setError("No leads found, search with different input");
          }
        } else {
          setLoading(false);
          setSearchSuccess(null);
          setError(errorMessages.FAILED_TO_FETCH);
          setEmptyList(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setSearchSuccess(null);
        setError(errorMessages.FAILED_TO_FETCH);
        setEmptyList(true);
        console.log(err);
      });
  }

  const onRowClick = (record, index, event) => {
    router.push(
      `/DisbursementLead/${record?.leadAuthId}/${
        filterStage || disbursementStages.ALL
      }`
    );
  };

  return (
    <Fragment>
      {loading ? (
        <div className="loader">
          <HashLoader color="#443eff" />
        </div>
      ) : (
      <div>
        <FormSection title="" subheading="">
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <div className="w-full">
                <form onSubmit={handleSubmit}>
                  <fieldset className="w-1200px">
                    <Section>
                      <Fieldset>
                        <Legend>
                          Search By Phone or ApplicationId or FirstName
                        </Legend>
                        <div className="mb-4 md:mb-6 w-60">
                          <Field
                            name="search.key"
                            component={Input}
                            label="Phone/App-Id/First-Name"
                            validate={(v) => {
                              if (!v) return "Mandatory";
                              v = v.trim();
                              if (!isValidLeadSearchInput(v))
                                return "Invalid Input";
                            }}
                          />
                        </div>
                        <div className="save-button">
                          <button type="submit" onClick={handleSubmit}>
                            Search
                          </button>
                        </div>
                        <div className="max-w-page-width mt-10 mb-4">
                          <Legend>Search By Offer Stages</Legend>
                          {Object.values(disbursementStages)
                            ?.slice(0, -1)
                            ?.map((stage) => (
                            <div
                              className="save-like-button-small-text text-xss inline-block mr-2 mb-2"
                              key={stage}
                            >
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFilterStage(stage);
                                }}
                                className="px-2"
                              >
                                {stage}
                              </button>
                            </div>
                          ))}
                        </div>
                      </Fieldset>
                    </Section>
                  </fieldset>
                </form>
              </div>
            )}
          </Form>
        </FormSection>
        <Fragment>
          <Fragment>
            {error ? (
              <h2 className="text-2xl text-center mb-6 text-[#FF5733]">
                {error}
              </h2>
            ) : (
              !emptyList && (
                <div>
                  <h2 className="text-2xl text-center mb-6">
                    Search Results For "{searchSuccess}"
                  </h2>
                  <Fragment>
                    <div className="w-full mt-6 px-8">
                      <SimpleTable
                        columns={columnsForLeadTable}
                        data={leadList}
                        onRow={(record, index) => ({
                          onClick: onRowClick.bind(null, record, index),
                        })}
                        rowClassName="hover:bg-gray-100"
                      />
                    </div>
                  </Fragment>
                </div>
              )
            )}
          </Fragment>
        </Fragment>
      </div>
      )}
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        pageCount={pageCount}
        onPageChange={handlePageClick}
        renderOnZeroPageCount={null}
        containerClassName="w-full flex justify-center my-4 text-gray-500 items-center"
        disabledClassName=""
        pageLinkClassName="py-1 px-2 m-1 cursor-pointer font-bold text-xl focus:text-white hover:bg-primary hover:text-white rounded"
        nextLinkClassName="py-1 px-2 m-1 pointer-events-none cursor-pointer font-bold text-xl hover:text-yellow-600"
        previousLinkClassName="py-1 px-2 m-1 pointer-events-none cursor-pointer font-bold text-xl hover:text-yellow-600"
        activeLinkClassName="bg-primary rounded text-white"
      />
    </Fragment>
  );
}

export default Search;
